import { useCallback } from "react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_REST_URL;

export function useDiscordConnect() {
    const connectToDiscord = useCallback(() => {
        // Navigate to the backend endpoint that initiates the Discord OAuth2 flow
        window.location.href = `${BACKEND_URL}/discord/link-discord`;
    }, []);

    return {
        connectToDiscord,
    };
}
