import {ReactElement} from "react";
import GoDexPageHeader from "../components/GoDexPageHeader";
import UserEdit from "../components/UserEdit";
import GoDexPageDpay from "../components/GoDexPageDpay";
import UserNotificationsEdit from "../components/UserNotificationsEdit";
import ReferralCodeGeneration from "../components/ReferralCodeGeneration";
import {AiFillCheckCircle} from "react-icons/ai";
import AccountMagicWalletPrivateKeyExportPanel
    from "../components/AccountMagicWalletPrivateKeyExportPanel";
import AccountDeletionPanel from "../components/AccountDeletionPanel";
import UserPaymentMethods from "../components/UserPaymentMethods";
import DevourTopNav from "@/components/DevourTopNav";
import {useGate} from "statsig-react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {UserType} from "@devour/client";
import AffiliateCodeGeneration from "@/components/AffiliateCodeGeneration";
import DiscordConnect from "@/components/DiscordConnect";

function AccountPage(): ReactElement {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    return (
        <div className="account-page">
            <DevourTopNav text="Account"/>
            <div className="account-page_container">
                <div className="account-page_header">
                    <GoDexPageHeader/>
                </div>

                <div className="account-page_dpay">
                    <GoDexPageDpay showWithdrawButton={true}/>
                </div>

                <div className="account-page_user-edit">
                    <UserEdit/>
                </div>

                {currentUser.user.type === UserType.PARTNER
                    ? <div className="account-page_affiliates" id="affiliates">
                        <h3>
                            Partner Affiliate
                        </h3>
                        <AffiliateCodeGeneration/>
                    </div>
                    : <div className="account-page_referrals" id="referafriend">
                        <h3>Refer-A-Friend</h3>
                        <div className="account-page_referrals_copy-container">
                            <AiFillCheckCircle
                                className="sign-up_content_referral-link-container_header_icon"/>
                            <p className="account-page_referrals_copy-container_description">
                                Give $30 in discounts to your friends
                                {dpayStatus ? `, and get $20 in ${import.meta.env.VITE_TOKEN_NAME} ` : " "}
                                when they place their first order!
                            </p>
                        </div>

                        <p>Grab your referral code or link below to share with friends to earn!</p>
                        <p className="referral-code-generation_input-container_text">
                            *First order must be over $15 to qualify
                        </p>
                        <ReferralCodeGeneration/>
                    </div>
                }

                <div className="account-page_payment-methods">
                    <h3>Payment Methods</h3>
                    <UserPaymentMethods/>
                </div>

                <div className="account-page_notification-settings">
                    <h3>Notification Settings</h3>
                    <UserNotificationsEdit/>
                </div>

                <div className="account-page_magic-export">
                    <AccountMagicWalletPrivateKeyExportPanel/>
                </div>

                <div className="account-page_discord-connect">
                    <h3>Connect with Discord</h3>
                    <DiscordConnect />
                </div>

                <div className="account-page_deactivate">
                    <h3>Manage Account</h3>
                    <AccountDeletionPanel/>
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
