import { FaDiscord } from "react-icons/fa";
import {useDiscordConnect} from "@/hooks/useDiscordConnect";

const DiscordConnect = () => {
    const { connectToDiscord } = useDiscordConnect();
    const handleDiscordSignIn = () => {
        connectToDiscord();
    };

    return (
        <div className="discord-connect">
            <div className="discord-connect_text">
                <p>
                    <strong>Discord Authentication</strong>
                </p>
                <p>
                    Connect to have direct communication with our community members and opportunities to earn rewards. Stay informed, get involved, and earn just by being a part of our Discord family!
                </p>
            </div>
            <button
                className="discord-connect_button"
                onClick={handleDiscordSignIn}
            >
                <FaDiscord className="discord-icon" />
                <span>Sign in with Discord</span>
            </button>
        </div>
    );
};

export default DiscordConnect;
